<template>
  <WsUpdate
    :modelName="modelName"
    :id="_id"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="_pageSettingSecondary"
    titleKey="name"
  >
  </WsUpdate>
</template>

<script>
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
import model from "@/__vue2stone_cms/models/service_store";
export default {
  metaInfo() {
    return {
      title: `${this.$t("編輯")} ${this.$t(model.label)}`,
    };
  },
  data() {
    const _showFields = [
      "name",
      "business_hours",
      "tel",
      "address",
      "des",
      // "parking_info",
      // "parking_link",
      "parking_infos",
      "parking_image",
      "transportation_info",
      "notify_emails",
    ];
    if (this.$config.wsmodule.appointment) {
      _showFields.push("appointment_availables");
    }
    return {
      ...model,
      pageSetting: {
        primary: [
          {
            type: "stateCard",
            floors: [
              {
                fields: _showFields,
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    _pageSettingSecondary() {
      const fields = [
        "is_active",
        "cover_image",
        "lat",
        "lng",
        "work_on_holiday",
        "service_at_night",
        "today_appointments_notify_time",
        "tomorrow_appointments_notify_time",
      ];
      if (S_App_Auth.hasScope(["boss"])) {
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            fields.push("cmser_groups");
          } else {
            fields.push("admin_groups");
          }
        }
        if (this.$config.wsmodule.country_code) {
          fields.push("country_code");
        }
      }
      if (this.$config.wsmodule.area) {
        fields.push("area");
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              title: "setting",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>